export const PageUrls = {
	HOMEPAGE: ['/'],
	KE_STAZENI: ['/ke-stazeni'],
	CERTIFIKACE: ['/ke-stazeni', 'certifikace'],
	SLOVNIK_POJMU: ['/slovnik-pojmu'],
	TECHNOLOGIE_PRUMYSLOVE_CISTIRNY: ['/technologie', 'prumyslove-cistirny-odpadnich-vod'],
	TECHNOLOGIE_PRUMYSLOVE_MYCI_NA_MIRU: ['/technologie', 'prumyslove-myci-technologie-na-miru'],
	TECHNOLOGIE_MYCI_LINKY: ['/technologie', 'myci-linky-a-rucni-tlakove-cisteni'],
	TECHNOLOGIE_SAMOOBSLUZNE_BOXY: ['/technologie', 'samoobsluzne-a-bezkontaktni-myci-boxy'],
	TECHNOLOGIE_SAMOOBSLUZNE_BOXY_KALKULATOR: ['/technologie', 'samoobsluzne-a-bezkontaktni-myci-boxy', 'kalkulacka-investice'],
	TECHNOLOGIE_SPECIALNI_LINKY: ['/technologie', 'specialni-myci-linky'],
	RESENI: ['/reseni'],
	REFERENCE: ['/reference'],
	O_NAS: ['/o-nas'],
	KARIERA: ['/kariera'],
	KONTAKT: ['/kontakt'],
	LEGAL: ['/legal'],
	LEGAL_OS_UDAJE: ['/legal', 'zasady-ochrany-osobnich-udaju'],
	LEGAL_VOP: ['/legal', 'vseobecne-obchodni-podminky'],
	LEGAL_COOKIES: ['/legal', 'zasady-pouzivani-souboru-cookie'],
};


